<template>
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.5789 4.45455C13.5789 3.65122 12.9277 3 12.1244 3H5.36842C4.61579 3 4 3.65455 4 4.45455V13.2679C4 14.0237 4.61266 14.6364 5.36842 14.6364V4.45455H13.5789ZM15.6316 5.90909H8.10526C7.35263 5.90909 6.73684 6.56364 6.73684 7.36364V17.5455C6.73684 18.3455 7.35263 19 8.10526 19H15.6316C16.3842 19 17 18.3455 17 17.5455V7.36364C17 6.56364 16.3842 5.90909 15.6316 5.90909ZM15.6316 17.5455H8.10526V7.36364H15.6316V17.5455Z"
      fill="#5C9DEB"
    />
  </svg>
</template>
<script>
export default {
  name: 'CopyIcon'
}
</script>
