<template>
  <svg
    width="42"
    height="46"
    viewBox="0 0 42 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_14533_7751)">
      <circle cx="26" cy="16" r="10" fill="#DCE1E7" />
      <rect
        x="21.7573"
        y="10.3433"
        width="14"
        height="2"
        rx="1"
        transform="rotate(45 21.7573 10.3433)"
        fill="#D45050"
      />
      <rect
        x="31.6567"
        y="11.7576"
        width="14"
        height="2"
        rx="1"
        transform="rotate(135 31.6567 11.7576)"
        fill="#D45050"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_14533_7751"
        x="0"
        y="-6"
        width="52"
        height="52"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="8" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_14533_7751"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_14533_7751"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'CloseIconRed'
}
</script>
