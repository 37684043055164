<template>
  <div class="overlay">
    <div class="list-keys">
      <div class="list-keys__header">
        <div class="header-top">
          <p
            class="font-SourceSansPro text-2xl font-bold"
            style="color: #292929"
          >
            {{ $t('reports.list_key') }}
          </p>
          <div class="list-keys__img">
            <!-- <InfoIcon class="cursor-pointer mr-4" /> -->
            <CloseIcon
              class="cursor-pointer"
              @click.native="$emit('close-list-key')"
            />
          </div>
        </div>
        <p
          class="font-normal text-sm font-SourceSansPro mb-2"
          style="color: #a6aeb8"
        >
          {{ $t('reports.copy_key') }}
        </p>
        <skif-search v-model.trim="searchStr" @searching="searching" />
      </div>
      <div v-if="isShowHint" class="list-keys__hint mt-6">
        <p
          class="font-SourceSansPro text-sm font-normal"
          style="color: #171717"
        >
          {{ $t('reports.key_hint') }}
        </p>
        <img :src="imgPath" alt="" />
        <CloseIconRed
          class="close cursor-pointer"
          @click.native="isShowHint = false"
        />
      </div>
      <div class="list-keys__keys">
        <div v-for="(items, typeName) in filteredData" :key="typeName">
          <p
            class="group-title font-SourceSansPro font-normal font-bold text-2xl"
            style="color: #828d9b"
          >
            {{ typeName }}
          </p>
          <div class="key-info" v-for="(item, index) in items" :key="index">
            <span
              class="font-SourceSansPro font-semibold text-sm"
              style="color: #828d9b"
            >
              {{ item.count }}
            </span>
            <span
              class="font-SourceSansPro font-semibold text-sm"
              style="color: #292929"
              >{{ item.name }}</span
            >
            <span
              class="font-SourceSansPro font-semibold text-sm"
              style="color: #828d9b"
              >{{ item.keyName }}</span
            >
            <SensorIcon v-if="item.has_sensor" />
            <span v-else></span>
            <CopyIcon
              class="copy-icon cursor-pointer"
              @click.native="copy(item, typeName)"
            />
            <div
              v-if="item.has_sensor && item.isModal"
              id="modalKey"
              class="menu"
            >
              <input
                v-model="maskInput"
                class="input"
                :placeholder="$t('reports.sensor_placeholder')"
              />
              <p class="hint">
                {{ $t('reports.mini_hint') }}
              </p>
              <skif-button
                :disabled="!maskInput.length"
                class="button"
                @click.native="copyMaskSensor(item)"
              >
                {{ $t('reports.copy') }}
              </skif-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import CloseIcon from './assets/BlackCloseIcon'
import CloseIconRed from './assets/CloseIconRed'
import SensorIcon from './assets/SensorIcon'
import CopyIcon from './assets/CopyIcon'
import { copyTextToClipboard } from '@/utils/helpers';

export default {
  name: 'ListKey',
  components: {
    CloseIcon,
    CloseIconRed,
    SensorIcon,
    CopyIcon
  },
  props: {
    id: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isShowHint: true,
      copyItem: null,
      searchStr: '',
      maskInput: ''
    }
  },
  computed: {
    ...mapGetters({
      tableKeys: 'reports/tableKeys',
      me: 'login/me'
    }),
    imgPath() {
      return this.me.lang.key === 'ru'
        ? require('./assets/tableHint.png')
        : require('./assets/tableHintEng.png')
    },
    filteredData() {
      return Object.keys(this.tableKeys).reduce((acc, type_name) => {
        acc[type_name] = this.tableKeys[type_name].filter((obj) => {
          const lowercaseSearch = this.searchStr.toLowerCase().trim()
          return Object.keys(obj).some((key) =>
            obj[key].toString().toLowerCase().includes(lowercaseSearch)
          )
        })
        return acc
      }, {})
    }
  },
  methods: {
    ...mapMutations({
      updateTableKey: 'reports/updateTableKey'
    }),
    ...mapActions({
      getTableKeys: 'reports/getTableKeys'
    }),
    searching(val) {
      this.searchStr = val
    },
    async copy(key, tableName) {
      const foundIndex = this.tableKeys[tableName].findIndex(
        (item) => item.keyName === key.keyName
      )
      if (foundIndex !== -1) {
        const isModal = !this.tableKeys[tableName].find(
          (el) => el.keyName === key.keyName
        ).isModal
        this.updateTableKey({ tableName, key, isModal })
        this.copyItem = { tableName, key, isModal }
      }
      await copyTextToClipboard(`${key.keyName}`)
      document.addEventListener('click', this.handleClickOutside)
    },
    handleClickOutside(event) {
      const modalMenu = document.querySelectorAll('.menu')
      if (modalMenu.length > 0) {
        if (
          !modalMenu[0].contains(event.target) &&
          !event.target.classList.contains('copy-icon')
        ) {
          this.closeModal()
        }
      }
    },
    closeModal() {
      if (this.copyItem) {
        const foundIndex = this.tableKeys[this.copyItem.tableName].findIndex(
          (item) => item.keyName === this.copyItem.key.keyName
        )
        if (foundIndex !== -1) {
          this.updateTableKey({
            tableName: this.copyItem.tableName,
            key: this.copyItem.key,
            isModal: false
          })
        }
      }
    },
    copyMaskSensor(item) {
      const mask = `{$${item.keyName}_${this.maskInput}}`
      navigator.clipboard.writeText(mask)
      this.maskInput = ''
      this.closeModal()
    },
    handleKedownEsc(event) {
      if (event.key === 'Escape') {
        this.$emit('close-list-key')
      }
    }
  },
  mounted() {
    this.getTableKeys(this.id)
    document.addEventListener('keydown', this.handleKedownEsc)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside)
    document.removeEventListener('keydown', this.handleKedownEsc)
  }
}
</script>

<style lang="scss" scoped>
.overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 30;
}
.list-keys {
  position: relative;
  width: 800px;
  margin: 0 auto;
  border-radius: 12px;
  background-color: #fff;
  &__header {
    padding: 20px;
    border-bottom: 1px solid #dce1e7;
    .header-top {
      display: flex;
      justify-content: space-between;
    }
  }
  &__hint {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 17px;
    margin: 20px;
    margin-bottom: 0;
    padding: 26px 16px;
    border-radius: 12px;
    background-color: #f6f7fa;
    .close {
      position: absolute;
      top: -13px;
      right: -13px;
    }
  }
  &__keys {
    overflow: scroll;
    height: 500px;
    .group-title {
      margin-bottom: 12px;
      margin-top: 28px;
      padding: 0 20px;
    }
    .key-info {
      display: grid;
      grid-template-columns: 0.1fr 1fr 1fr 0.1fr 0.1fr;
      position: relative;
      padding: 6px 20px;
      &:nth-of-type(even) {
        background-color: #f6f7fa;
      }
    }
  }
}
.menu {
  position: absolute;
  right: 60px;
  top: 18px;
  width: 262px;
  z-index: 1;
  padding: 8px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 14px 0px #00000014;
  .input {
    width: 100%;
    background-color: #edeff3;
    border-radius: 10px;
    border: none;
    outline: none;
    outline: 0;
    padding-left: 10px;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    margin-bottom: 8px;
    height: 36px;
  }
  .hint {
    background-color: #dbebff;
    padding: 10px;
    border-radius: 12px;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #20579a;
  }
  .button {
    margin-top: 8px;
    width: 100%;
    background-color: #3a7cca;
  }
}
</style>
