<template>
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="14" cy="14" r="14" fill="#EDEFF3" />
    <path
      d="M20 9.20857L18.7914 8L14 12.7914L9.20857 8L8 9.20857L12.7914 14L8 18.7914L9.20857 20L14 15.2086L18.7914 20L20 18.7914L15.2086 14L20 9.20857Z"
      fill="#292929"
    />
  </svg>
</template>

<script>
export default {
  name: 'CloseIcon'
}
</script>
