<template>
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="8" y="8" width="6" height="6" fill="#CDD4DD" />
    <path
      d="M8.08333 4V5.16667M5.16667 8.08333H4M18 8.08333H16.8333M5.16667 11H4M18 11H16.8333M5.16667 13.9167H4M18 13.9167H16.8333M8.08333 16.8333V18M11 4V5.16667M11 16.8333V18M13.9167 4V5.16667M13.9167 16.8333V18M6.91667 16.8333H15.0833C15.5475 16.8333 15.9926 16.649 16.3208 16.3208C16.649 15.9926 16.8333 15.5475 16.8333 15.0833V6.91667C16.8333 6.45254 16.649 6.00742 16.3208 5.67923C15.9926 5.35104 15.5475 5.16667 15.0833 5.16667H6.91667C6.45254 5.16667 6.00742 5.35104 5.67923 5.67923C5.35104 6.00742 5.16667 6.45254 5.16667 6.91667V15.0833C5.16667 15.5475 5.35104 15.9926 5.67923 16.3208C6.00742 16.649 6.45254 16.8333 6.91667 16.8333ZM7.5 7.5H14.5V14.5H7.5V7.5Z"
      stroke="#CDD4DD"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'SensorIcon'
}
</script>
